<div class="icon-container">
  @if(showScrollButton()){
  <p-button class="scroll-button" icon="pi pi-chevron-left" (click)="scroll('left')" />
  }
  <div class="linkedIconsGrid" [id]="randomUniqueId()">
    <ul>
      <li *ngFor="let key of Object.keys(assetLinkedDetails); let index = index" [pTooltip]="badgeColors[key]?.tooltip"
        tooltipPosition="top" class="asset-linked-container">
          @if(assetLinkedDetails[key]?.length){
            <div class="badge-container"   [style.--badge-background-color]="badgeColors[key]?.color" pBadge [value]="assetLinkedDetails[key]?.length > 99 ? '99+' : assetLinkedDetails[key]?.length">
              <img  [src]="assetLinkedDetails[key]?.icon" alt="icon" (load)="checkIfScrollingNeeded(Object.keys(assetLinkedDetails).length,index)"/>
            </div>
          }@else{
            <img [src]="assetLinkedDetails[key]?.icon" alt="icon" (load)="checkIfScrollingNeeded(Object.keys(assetLinkedDetails).length,index)"/>
          }
      </li>
    </ul>
  </div>
  @if(showScrollButton()){
  <p-button class="scroll-button" icon="pi pi-chevron-right" (click)="scroll('right')" />
  }
</div>