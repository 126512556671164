import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
declare const require: any;
import { EChartDataType , EChartSeriesUpdate } from './e-charts-data-type';
import { cloneDeep, forEach } from 'lodash';
@Component({
  selector: 'app-e-charts',
  templateUrl: './e-charts.component.html',
  styleUrls: ['./e-charts.component.scss'],

})
export class EChartsComponent implements OnInit, OnChanges {
  @Input() chartData: EChartDataType;
  @Input() graphTypeChangedRandomNumber: number;
  @Input() loader = false;
  @Input() show = false;
  @Input() type = 'line';
  @Input() tooltipAxisPointerType = 'line'; // it can be 'none', 'cross', 'shadow', 'line'
  @Input() updateValue: EChartSeriesUpdate;
  @Input() maxSeriesLength = 20;
  @Input() isSmallEChart: boolean;
  @Input() labelPosition: string;
  @Input() topPadding: number;
  @Input() gridSpacing: object;
  @Input() gridHeight: string;
  @Input() gridTop: string;
  @Input() isZoomRequired: boolean = false;
  @Input() isTotalNotRequired: boolean = false;
  @Input() forceChartHeightInPx: string;
  @Output() sendDataToPlotMarkerOnMap: EventEmitter<any> = new EventEmitter();
  @Output() sendDataToOpenInModal: EventEmitter<any> = new EventEmitter();
//   dataZoomOption = {
//     id: 'dataZoomX',
//     type: 'slider',
//     xAxisIndex: [0],
//     filterMode: 'filter',
//     start: 10,
//     end: 15
// }

  dataZoomOption = [
    {
      type: 'slider',
      show: true,
      // start: 100,
      // end: 50,
      handleSize: '80%'
    },
    {
      type: 'inside',
      // start: 100,
      // end: 50
    }
  ];

  updateOptions: any;
  defaultGridSpacing = {left: '3%', right: '4%', bottom: '3%', containLabel: true};

  isLoading = this.loader;
  options = null;
  showGraph = false;
  showDataUnavailabe = false;
  isAtleastOneDataAvailabe = false;

  constructor() {
    this.isSmallEChart = false;
    this.forceChartHeightInPx = null;
  }

  ngOnInit() {
 }

 onChartClick(event: any) {
  this.sendDataToPlotMarkerOnMap.emit(event);
  this.sendDataToOpenInModal.emit(event);
}
  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'chartData') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.updateGraph(this.chartData);
        }
      }
      if (propName === 'graphTypeChangedRandomNumber') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.updateGraph(this.chartData);
        }
      }
      if (propName === 'loader') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.isLoading = this.loader;
        }
      }
      if (propName === 'show') {
        if (typeof change.currentValue==='boolean') {
          this.showGraph = this.show;
        }
      }
      if (propName === 'updateValue') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.updateGraphSeries(this.updateValue);
        }
      }
    }
  }

  capitalizeFirstLetter(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  updateGraph(data: EChartDataType) {
      data = cloneDeep(data);
    let localThis = this
    this.isAtleastOneDataAvailabe = false;
    data['isDataZoomRequired'] = false;
    if (Object.keys(data).length > 0) {
      if (data['series'] && data['series'].length < 1) {
        data['isDataZoomRequired'] = false;
      } else {
        let temp = 0;
        for (const i in data['series']) {
          if (data['series'][i]['data']  && data['series'][i]['data'].length>0) {
            temp = temp + data['series'][i]['data'].length;
            if (temp > 150) {
              data['isDataZoomRequired'] = true;
            } else {
              data['isDataZoomRequired'] = false;
            }
            this.showDataUnavailabe = false;
            this.isAtleastOneDataAvailabe = true;

          } else {
            this.isAtleastOneDataAvailabe && data['series'] && data['series'].length > 1 ? this.showDataUnavailabe = false : this.showDataUnavailabe = true
             // this.showDataUnavailabe = true
            continue;
          }
        }
      }
    }

    this.options = {
      cursor: data.cursor || 'pointer', 
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        axisPointer: {
          type: this.tooltipAxisPointerType,
          label: {
            backgroundColor: '#688581'
          }
        },
        formatter:  (params)=> {
          let rez ='';
          let total = 0;
          const tooltipData=this.chartData?.series?.[0]?.['addDataForTooltip']?.[params?.[0]?.name]

          if(tooltipData){
            rez = params[0]['name'] + '  (<B>' + tooltipData['temperature'] + '°C </B>)' + '<br/>';
            for(let key in tooltipData){
              if(key!=='temperature'){
                rez+= (params?.[0].marker || '') + ' ' + this.capitalizeFirstLetter(key).split("_").join(' ') + ' : ' + tooltipData[key] + '<br/>';
              }
            }
          }
          else {
            rez = params[0]['name'] + '<br/>';
            let count = 1;
            params.forEach((item) => {
              const data = item.data;
              if(item.data || item.data === 0) {
                  const text = item.seriesName;
                  const result = text.replace( /([A-Z])/g, ' $1' );
                  const finalSeriesName = result.charAt(0).toUpperCase() + result.slice(1);
                  total +=data
                  const xx = (item.marker) + ' ' + finalSeriesName + ' : ' + item.data 
                             + (
                              !this.chartData?.['tooltipNumberOfInlineValues'] ? '<br/>' :                 // showing 1 item in one line
                              count!==this.chartData?.['tooltipNumberOfInlineValues'] ? ' , ' : '<br/>' // showing n items in one line based on tooltipNumberOfInlineValues
                              );
                  rez += xx;
                  if(count===this.chartData?.['tooltipNumberOfInlineValues']){
                    count = 0
                  }
                  count++;
              }
            });
          }
              if(!localThis.isTotalNotRequired){
                  rez += `<br> <B>Total</B> : ${total}`
              }

          return rez;
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        itemSize: 13,
       // itemGap: 12,
        showTitle: false,
        feature: {
          dataZoom: {
            show: false,
            yAxisIndex: 'none'
          },
          dataView: {
            readOnly: false
          },
          magicType: {
            show: false,
            type: ['line', 'bar', 'stack']
          },
          restore: {
            show: false,
          },
          saveAsImage: {
            name: 'Chart'
          }
        },
        tooltip: { // same as option.tooltip
            show: true,
            formatter: function (param) {
                return '<div>' + param.title + '</div>'; // user-defined DOM structure
            },
            backgroundColor: '#fff',
            textStyle: {
                fontSize: 12,
            },
            extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);' // user-defined CSS styles
        }
      },
      legend: {
        selected: {},
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 20,
        // bottom: 20,
        padding: [
          10,  // up
          0, // right
          0,  // down
          0, // left
        ],
        data: data.legend,
        selectorLabel: {
          color: 'white'
        },
        textStyle: {
          color: sessionStorage.getItem('theme') === 'dark-theme' ? 'white' : 'black'
        },
        formatter: function (name) {
         // let result = text.replace( /([A-Z])/g, ' $1' );
          let result = name;
          let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          return finalResult;
        },

      },
      grid: { containLabel: true },
      xAxis: [
        {
         type: 'category',
         boundaryGap: true,
          data: data.xAxis,
          axisLine: {
            lineStyle : {
              color: 'green',
            }
          },
          axisLabel: {
            interval: 0,
            showMaxLabel: true,
          },
        }
      ],
      dataZoom:
      // (this.isZoomRequired ? [this.dataZoomOption] : [{}]),
      this.dataZoomOption,
      yAxis: [
        {
          type: 'value',
          minInterval: 1,
          axisLine: {
            lineStyle: {
              // color : 'green',
              color: (sessionStorage.getItem('theme')) === 'dark-theme' ? 'white' : 'black'
            }
          }
        }
      ],
      visualMap : this.chartData?.visualMap,
      series: [],
      title: {
        text: data.title,
        x: this.labelPosition ? this.labelPosition : 'center',
        textStyle: {
          color : (sessionStorage.getItem('theme')) === 'dark-theme' ? '#d0d0d0' : '#212529',
          fontSize: 17,
          fontWeight: '500',
          fontFamily: '"robotomedium", sans-serif',
        }
      },
    };

    if(data?.legendToDisable){
        localThis.options['legend']['selected'][data?.legendToDisable] = false
    }

    this.options.grid = this.gridSpacing ? this.gridSpacing : this.defaultGridSpacing;
    this.gridHeight ? this.options['grid']['height'] = this.gridHeight : '100%'
    this.gridTop ? this.options['grid']['top'] = this.gridTop : ''
    this.options.xAxis = [{
      type: 'category',
      boundaryGap: true,
      data: data.xAxis,
      axisLine: {
        lineStyle : {
          // color : 'green',
          color : (sessionStorage.getItem('theme')) === 'dark-theme' ? 'white' : 'black'
        }
      },
      axisLabel: {
        rotate: data.labelRotate
      }

    }];
    this.options.series = data.series;
    if ( data.isDataZoomRequired ) {
      this.options.dataZoom = [[
        {
        type: 'slider',
        show: true,
        // start: 100,
        // end: 50,
        handleSize: '80%'
      },
      {
        type: 'inside',
        // start: 100,
        // end: 50
      }]];
    }
    if ( data.tooltip ) {

      this.options.tooltip = data.tooltip;
    }
  }

  updateGraphSeries(data: EChartSeriesUpdate) {
    this.showDataUnavailabe = false
    const _this = this;
    if(data.value || data.value == 0) {
      if (this.options && this.options.series
        && this.options.series[data.index]
        && this.options.series[data.index].data) {
        const seriesData = this.options.series[data.index].data;
        const xAxis = this.options.xAxis[data.index].data;
        if ((seriesData.length && xAxis.length) >= this.maxSeriesLength) {
          seriesData.shift();
          xAxis.shift();
        }
        seriesData.push(data.value);
        xAxis.push(data.label);
        this.updateOptions = {
          series: [{data: seriesData}],
          xAxis: [{data: xAxis}]
        };
      }
    } else if (data.values && data.values.length > 0){

      let seriesDynamicVar: any = {};
      let seriesDatas = [];
      let xAxis = [];
      if (this.options && this.options.xAxis
        && this.options.xAxis[data.labelIndex]
        && this.options.xAxis[data.labelIndex].data) {
          xAxis = this.options.xAxis[data.labelIndex].data;
          if ((xAxis.length) >= this.maxSeriesLength) {
            xAxis.shift();
          }
          xAxis.push(data.label);
      }

      forEach(data.values, function(value, index) {

        if (_this.options && _this.options.series
          && _this.options.series[index]
          && _this.options.series[index].data) {
            seriesDynamicVar[index] = _this.options.series[index].data;
            if ((seriesDynamicVar[index].length) >= _this.maxSeriesLength) {
              seriesDynamicVar[index].shift();
            }
            seriesDynamicVar[index].push(value);
            seriesDatas.push({data: seriesDynamicVar[index]})
        }

      });

      this.updateOptions = {
        series: seriesDatas,
        xAxis: [{data: xAxis}]
      };

    }
  }

}
