import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ForbiddenComponent} from './shared/forbidden/forbidden.component';
import {AuthGuard} from '../auth/auth.guard';
import {Actions, Subject} from '../auth/rules';
import {DashboardComponent} from './dashboard/dashboard.component';
import {CustomPreloadingService} from '../services/custom-preloading.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {path: 'user/reset-password', component: ResetPasswordComponent},
  /* { path: '**' , component : HomeComponent},*/
  {
    path: 'privacy-policy',
    loadComponent: () => import('./standalone-components/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },

  {path: 'forbidden', component: ForbiddenComponent, canActivate: [AuthGuard]},
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {auth: {subject: Subject.DASHBOARD, action: Actions.READ}}
  },
  {
    path: 'asset',
    loadChildren: () => import('./asset-list/asset-list.module').then(m => m.AssetListModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.ASSET, action: Actions.READ}}
  },
  {
    path: 'nearable',
    loadChildren: () => import('./nearable-component/discover.module').then(m => m.DiscoverModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.DISCOVER, action: Actions.READ}}
  },
  {
    path: 'fleet',
    loadChildren: () => import('./new-fleet/new-fleet.module').then(m => m.FleetModule),
    canActivate: [AuthGuard],
    data: {auth: {subject: Subject.TRACKER, action: Actions.LIVE}}
  },
  {
    path: 'inspection',
    loadChildren: () => import('./inspection/inspection.module').then(m => m.InspectionModule),
    canActivate: [AuthGuard],
    data: {auth: {subject: Subject.INSPECTION, action: Actions.LIVE}}
  },
  {
    path: 'security',
    loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
    canActivate: [AuthGuard],
    data: {auth: {subject: Subject.SECURITY, action: Actions.READ}}
  },
  {
    path: 'groups',
    loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.GROUP, action: Actions.READ}}
  },
  {
    path: 'services',
    loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.SERVICE, action: Actions.READ}}
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.SUPER_ACCOUNT, action: Actions.READ}}
  },
  {
    path: 'config',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.SETTINGS, action: Actions.READ}},
  },

  {
    path: 'attendance',
    loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.ATTENDANCE, action: Actions.READ}},
  },
  {
    path: 'digitalKey',
    loadChildren: () => import('./key-management/key-management.module').then(m => m.KeyManagementModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.KEY_MANAGEMENT, action: Actions.READ}},
  },
  {
    path: 'LMD',
    loadChildren: () => import('./delivery-managenment-system/last-mile-delivery/last-mile-delivery.module').then(m => m.LastMileDeliveryModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.LMD_MANAGEMENT, action: Actions.READ}}
  },
  {
    path: 'O2D',
    loadChildren: () => import('./o2d/o2d.module').then(m => m.O2DModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.O2D_MANAGEMENT, action: Actions.READ}}
  },
  {
    path: 'MMD',
    loadChildren: () => import('./delivery-managenment-system/middle-mile-delivery/middle-mile-delivery.module').then(m => m.MiddleMileDeliveryModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.MMD_MANAGEMENT, action: Actions.READ}}
  },
  {
    path: 'KIOSK',
    loadChildren: () => import('./kiosk/kiosk.module').then(m => m.KioskModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.KIOSK_MANAGEMENT, action: Actions.READ}}
  },
  {
    path: 'warehouse',
    loadChildren: () => import('./warehouse/warehouse.module').then(m => m.WarehouseModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.WAREHOUSE, action: Actions.READ}}
  },
  {
    path: 'authorization',
    loadChildren: () => import('./authorization/authorization.module').then(m => m.AuthorizationModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.AUTHORIZATION, action: Actions.READ}}
  } ,
  {
    path: 'accountLevelSettings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.ACCOUNT_SETTINGS, action: Actions.READ}},
  },
  {
    path: 'communication',
    loadChildren: () => import('./communication-details/communication.module').then(m => m.CommunicationDetailsModule),
    canActivate: [AuthGuard], data: {auth: {subject: Subject.COMMUNICATION, action: Actions.READ}},
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingService })],
  // imports: [RouterModule.forRoot(routes, {preloadingStrategy: QuicklinkStrategy})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
