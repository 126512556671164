import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";



export const selectLogin = (state:AppState) => state.login


export const getLoginResponse = createSelector(
    selectLogin,
    (login) => login.res
)

export const getLoginStatus = createSelector(
    selectLogin,
    (login) => login.status
)

export const getLoginError = createSelector(
    selectLogin,
    (login) => login.error
)

export const getToken = createSelector(
    selectLogin,
    (login) => login.token
)

export const getIsUserAuthenticated = createSelector(
    selectLogin,
    (login) => login
)
export const getAccountLicenseExpiryMsg = createSelector(
    selectLogin, 
    (login)=>{return  login}
)
export const getAccountLicenseExpiryAlert = createSelector(
    selectLogin, 
    (login)=>{return login.accountLicenceInfoState.accountLicenseExpiryAlert}
)
export const getAccountLicenseExpiryAlertMsg = createSelector(
    selectLogin,
    (login)=>{return login.accountLicenceInfoState}
)

export const getSessionTimeout = createSelector(
    selectLogin,
    (login)=> login.showToast
)

export const getAccountFromLoginResponse = createSelector(
    selectLogin,
    (login) => login.account
)

export const getiotZenServerVersionNumber = createSelector(
    selectLogin,
    (login) => login.iotZenServerVersion
)

export const getKioskSubscriptionData = createSelector(
    selectLogin,
    (login) => login.kioskSubscriptionData
)

export const getInternetConnectivityState = createSelector(
    selectLogin,
    (login) => login.isBannerVisibleForNoInternet
)

export const getPrinterConnectivityState = createSelector(
    selectLogin,
    (login) => login.bannerDataForPrinterError
)

export const getResetPasswordLinkMsg = createSelector(
    selectLogin,
    (login) => login.resetPasswordLinkMsg
)

export const resetPasswordErrorMsg = createSelector(
    selectLogin,
    (login) => login.resetPasswordError
)