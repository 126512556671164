import { Injectable } from "@angular/core";
import { SuperAdminSettingsActions } from ".";
import { Actions, createEffect , ofType} from "@ngrx/effects";
import { switchMap ,from, catchError, of, mergeMap, withLatestFrom,tap} from "rxjs";
import { SuperAdminSettingsService } from "./superadminsettings.service";
import { Account, IKioskThemeType, Role, User, UserProfile } from "./interfaces/superadminsettings.interface";
import { Store } from "@ngrx/store";
import { getAllAbilityTogether, getAllAvailabeModules, getThemeTypesWithThemeName } from "./superadminsettings.selector";
import { MessageService } from "primeng/api";
import { IAllModules } from "../../app/settings/services/account-management.service";


@Injectable()
export class SuperAdminSettingsEffects {
  constructor(
    private store:Store,
    private actions$: Actions,
    private superAdminSettings: SuperAdminSettingsService,
    private messageService:MessageService,
  ) {}


  loadRolesCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminSettingsActions.getRolesCount),
      switchMap(() => {
        return from(this.superAdminSettings.getCountOfRoles()).pipe(
          switchMap((res: { count: number }) => {
            return [
              SuperAdminSettingsActions.onRolesCountSuccuss({
                count: res.count,
              }),
            ];
          }),
          catchError((error) =>
            of(SuperAdminSettingsActions.onRolesCountFailure({ err: error }))
          )
        );
      })
    )
  );

  loadRolesDataByPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuperAdminSettingsActions.getRolesDataByPage),
      mergeMap((action) => {
        return from(
          this.superAdminSettings.getRoles(action.skip, action.limit)
        ).pipe(
          switchMap((res: Role[]) => {
            return [
              SuperAdminSettingsActions.onRolesDataByPageSuccuss({
                roles: res,
                page: action.page,
              }),
            ];
          }),
          catchError((error) =>
            of(
              SuperAdminSettingsActions.onRolesDataByPageFailure({ err: error })
            )
          )
        );
      })
    )
  );

  loadIndividualRole$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.getIndividualRole),
    switchMap((action) => {
      return from(this.superAdminSettings.getIndividualRoleData(action.name)).pipe(
        switchMap((res:Role) => {
          return [
            SuperAdminSettingsActions.onIndividualRoleSuccuss({
              role: res,
            }),
          ];
        }),
        catchError((error) =>
          of(SuperAdminSettingsActions.onIndividualRoleFailure({ err: error }))
        )
      );
    })
  )
);

saveNewRole$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.saveNewRole),
  switchMap((action) => {
    return from(this.superAdminSettings.saveNewRole(action.role)).pipe(
      switchMap((res:{message:string,data:Role} ) => {
        return [
          SuperAdminSettingsActions.onNewRoleSavedSuccuss({
          status: res,
          currentLimit: action.currentLimit
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onNewRoleSaveFailure({ err: error }))
      )
    );
  })
)
);

updateRole$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.updateRole),
  switchMap((action) => {
    return from(this.superAdminSettings.updateRole(action.roleId,action.roleData)).pipe(
      switchMap((res:{message:string,data:Role} ) => {
        return [
          SuperAdminSettingsActions.onRoleUpdationSuccuss({
          status: res,
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onRoleUpdationFailure({ err: error }))
      )
    );
  })
)
);

deleteRole$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.deleteRole),
  switchMap((action) => {
    return from(this.superAdminSettings.deleteRole(action.roleId)).pipe(
      switchMap((res:{message:string,data:Role}) => {
        return [
          SuperAdminSettingsActions.onRoleDeletionSuccuss({
         roleId:action.roleId,
         status:res,
         currentLimit:action.currentLimit
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onRoleDeletionFailure({ err: error }))
      )
    );
  })
)
);



loadAccountsCount$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.getAccountsCount),
  switchMap((action) => {
    return from(this.superAdminSettings.getCountOfAccounts(action.fetchSubAccounts)).pipe(
      switchMap((res: { count: number }) => {
        return [
          SuperAdminSettingsActions.onAccountsCountSuccuss({
            count: res.count,
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onAccountsCountFailure({ err: error }))
      )
    );
  })
)
);

loadAccountsDataByPage$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.getAccountsDataByPage),
  mergeMap((action) => {
    return from(
      this.superAdminSettings.getAccounts(action.skip, action.limit,action.fetchSubAccounts)
    ).pipe(
      switchMap((res: Account[]) => {
        return [
          SuperAdminSettingsActions.onAccountsDataByPageSuccuss({
            accounts: res,
            page: action.page,
          }),
        ];
      }),
      catchError((error) =>
        of(
          SuperAdminSettingsActions.onAccountsDataByPageFailure({ err: error })
        )
      )
    );
  })
)
);

loadIndividualAccount$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.getIndividualAccount),
  switchMap((action) => {
    return from(this.superAdminSettings.getIndividualAccountData(action.accountId)).pipe(
      switchMap((res:Account) => {
        return [
          SuperAdminSettingsActions.onIndividualAccountSuccuss({
            account:res
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onIndividualAccountFailure({ err: error }))
      )
    );
  })
)
);


updateAccount$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.updateAccount),
  switchMap((action) => {
    return from(this.superAdminSettings.updateAccount(action.accountData)).pipe(
      switchMap((res:Account ) => {
        return [
          SuperAdminSettingsActions.onAccountUpdationSuccuss({
            account:res
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onAccountUpdationFailure({ err: error }))
      )
    );
  })
)
);

saveNewAccount$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.saveNewAccount),
  switchMap((action) => {
    return from(this.superAdminSettings.saveNewAccount(action.account)).pipe(
      switchMap((res:Account ) => {
        return [
          SuperAdminSettingsActions.onNewAccountSavedSuccuss({
          account:res,
          currentLimit: action.currentLimit
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onNewAccountSaveFailure({ err: error }))
      )
    );
  })
)
);

deleteAccount$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.deleteAccount),
  switchMap((action) => {
    return from(this.superAdminSettings.deleteAccount(action.accountId)).pipe(
      switchMap((res:{msg:string,data:Account}) => {
        return [
          SuperAdminSettingsActions.onAccountDeletionSuccuss({
         accountId:action.accountId,
         status:res,
         currentLimit:action.currentLimit
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onAccountDeletionFailure({ err: error }))
      )
    );
  })
)
);


loadUsersCount$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.getUsersCount),
  switchMap((action) => {
    return from(this.superAdminSettings.getCountOfUsers(action.allCount,action.searchText,action.rolesArrayToSting,action.accountId,action.isAccountLevel)).pipe(
      switchMap((res: { count: number }) => {
        return [
          SuperAdminSettingsActions.onUsersCountSuccuss({
            count: res.count,
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onUsersCountFailure({ err: error }))
      )
    );
  })
)
);

loadUsersDataByPage$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.getUsersDataByPage),
  mergeMap((action) => {
    return from(
      this.superAdminSettings.getUsers(action.skip, action.limit, action.isAllAccount, action.searchText, action.role, action.accountId,action.isAccountLevel)
    ).pipe(
      switchMap((res: User[]) => {
        return [
          SuperAdminSettingsActions.onUsersDataByPageSuccuss({
            users: res,
            page: action.page,
            isAccountLevel:action.isAccountLevel
          }),
        ];
      }),
      catchError((error) =>
        of(
          SuperAdminSettingsActions.onUsersDataByPageFailure({ err: error })
        )
      )
    );
  })
)
);



loadIndividualUser$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.getIndividualUser),
  switchMap((action) => {
    return from(this.superAdminSettings.getIndividualUserData(action.userId)).pipe(
      switchMap((res:UserProfile) => {
        return [
          SuperAdminSettingsActions.onIndividualUserSuccuss({
            user:res
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onIndividualUserFailure({ err: error }))
      )
    );
  })
)
);


updateUser$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.updateUser),
  switchMap((action) => {
    return from(this.superAdminSettings.updateUser(action.userId,action.userData)).pipe(
      switchMap((res:UserProfile) => {
        return [
          SuperAdminSettingsActions.onUserUpdationSuccuss({
            user:res,
            updatedData:action.userData
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onUserUpdationFailure({ err: error }))
      )
    );
  })
)
);

saveNewUser$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.saveNewUser),
  switchMap((action) => {
    return from(this.superAdminSettings.saveNewUser(action.user,action.isAccountLevel)).pipe(
      switchMap((res:{msg:string,obj:User}) => {
        return [
          SuperAdminSettingsActions.onNewUserSavedSuccuss({
          status:res,
          currentLimit: action.currentLimit
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onNewUserSaveFailure({ err: error }))
      )
    );
  })
)
);


deleteUser$ = createEffect(() =>
this.actions$.pipe(
  ofType(SuperAdminSettingsActions.deleteUser),
  switchMap((action) => {
    return from(this.superAdminSettings.deleteUser(action.userId)).pipe(
      switchMap((res:{msg:string,data:User}) => {
        return [
          SuperAdminSettingsActions.onUserDeletionSuccuss({
         userId:action.userId,
         status:res,
         currentLimit:action.currentLimit
          }),
        ];
      }),
      catchError((error) =>
        of(SuperAdminSettingsActions.onUserDeletionFailure({ err: error }))
      )
    );
  })
)
);

effectgetThemeNameFromThemetype$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.getThemeNamesFromThemeType),
    withLatestFrom(this.store.select(getThemeTypesWithThemeName)),
    switchMap(([action,stateData]) => {
      if(stateData?.dark && stateData?.light){
        return of(SuperAdminSettingsActions.getThemeNamesFromThemeTypeSuccess({res:stateData[action.themeType],themeType:action.themeType}))
      }else{
        return from(this.superAdminSettings.getThemeName(action.themeType)).pipe(
          switchMap((res:IKioskThemeType) => {
            return [SuperAdminSettingsActions.getThemeNamesFromThemeTypeSuccess({res:res,themeType:action.themeType})];
          }),
          catchError(() => {
            return []
          })
        );
      }

    })
  )
);

setNewThemeFromThemeType$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.setNewThemeNamesFromThemeType),
    switchMap((action) => {
      return from(this.superAdminSettings.saveNewThemesWithThemeName(action.payload)).pipe(
        tap(() => {
          this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Saved!', detail: 'Successfully Added New Theme'});
      }),
        switchMap((res:any) => {
          return [SuperAdminSettingsActions.setNewThemeNamesFromThemeTypeSuccess({res:res,themeType:action.payload.themeType})];
        }),
        catchError(() =>{
          return []
        }
        )
      );
    })
  )
);

deleteThemeFromThemeId$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.deleteThemesFromThemeId),
    switchMap((action) => {
      return from(this.superAdminSettings.deleteThemeWithThemeID(action.themeId)).pipe(
        tap(() => {
          this.messageService.add({ key: 'global-notification', severity: 'error', summary: 'Deleted', detail: 'Successfully Deleted Theme'});
      }),
        switchMap(() => {
          return [SuperAdminSettingsActions.deleteThemesFromThemeIdSuccess({themeId:action.themeId,themeType:action.themeType})];
        }),
        catchError(() =>{
          return []
        }
        )
      );
    })
  )
);

updateThemeFromThemeId$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.updateThemesFromThemeId),
    switchMap((action) => {
      return from(this.superAdminSettings.updateThemeWithThemeID(action.themeId,action.payload)).pipe(
        tap(() => {
          this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: 'Successfully Updated Theme'});
      }),
        switchMap(() => {
          return [SuperAdminSettingsActions.updateThemesFromThemeIdSuccess({themeId:action.themeId,themeType:action.themeType,payload:action.payload})];
        }),
        catchError(() =>{
          return []
        }
        )
      );
    })
  )
);

getAllThemesWithTypeId$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.getAllThemesWithTypeId),
    switchMap(() => {
      return from(this.superAdminSettings.getAllThemes()).pipe(
        switchMap((res:any) => {
          return [SuperAdminSettingsActions.getAllThemesWithTypeIdSuccess({res:res})];
        }),
        catchError(() =>{
          return []
        }
        )
      );
    })
  )
);

saveAbility$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.saveAbility),
    switchMap((action) => {
      return from(this.superAdminSettings.saveAbility(action.payload)).pipe(
        tap(() => {
          this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'New Ability', detail: 'Ability Created Successfully!'});
      }),
        switchMap((res:any) => {
          return [SuperAdminSettingsActions.saveAbilitySuccess({res:res})];
        }),
        catchError((error) =>
          of(SuperAdminSettingsActions.saveAbilityError({ err: error }))
        )
      );
    })
  )
);


getAllAbilityCount$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.getAllAbilityCount),
    switchMap(() => {
      return from(this.superAdminSettings.getCountOfAbility()).pipe(
        switchMap((res:any) => {
          return [SuperAdminSettingsActions.getAllAbilityCountSuccess({count:res.count})];
        }),
        catchError((error) =>
          of(SuperAdminSettingsActions.saveAbilityError({ err: error }))
        )
      );
    })
  )
);

deleteAbility$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.deleteAbility),
    switchMap((action) => {
      return from(this.superAdminSettings.deleteAbilityWithId(action.id)).pipe(
        tap(() => {
          this.messageService.add({ key: 'global-notification', severity: 'error', summary: 'Ability Deleted', detail: 'Ability Deleted Successfully!'});
        }),
        switchMap(() => {
          return [SuperAdminSettingsActions.deleteAbilitySuccess({id:action.id})];
        }),
        catchError((error) =>
          of(SuperAdminSettingsActions.saveAbilityError({ err: error }))
        )
      );
    })
  )
);

getAbilityWithCount$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.getAllAbilityWithCount),
    withLatestFrom(this.store.select(getAllAbilityTogether)),
    switchMap(([action,stateData]) => {
      if(stateData){
        return of(SuperAdminSettingsActions.getAllAbilityWithCountSuccess({res:stateData}));
      }else{
        return from(this.superAdminSettings.getAllAbility(0,action.limit)).pipe(
          switchMap((res) => {
            return [SuperAdminSettingsActions.getAllAbilityWithCountSuccess({res:res})];
          }),
          catchError((error) =>
            of(SuperAdminSettingsActions.saveAbilityError({ err: error }))
          )
        );
      }
    })
  )
);

getAbilityById$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.getAbilityWithId),
    switchMap((action) => {
      return from(this.superAdminSettings.getAbilityWithId(action.id)).pipe(
        switchMap((res:any) => {
          return [SuperAdminSettingsActions.getAbilityWithIdSuccess({res:res})];
        }),
        catchError((error) =>
          of(SuperAdminSettingsActions.saveAbilityError({ err: error }))
        )
      );
    })
  )
);
updateAbilityById$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.updateAbilityWithId),
    switchMap((action) => {
      return from(this.superAdminSettings.updateAbilityWithId(action.id,action.payload)).pipe(
        tap(() => {
          this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Ability Updated', detail: 'Ability Updated Successfully!'});
        }),
        switchMap((res:any) => {
          return [SuperAdminSettingsActions.updateAbilityWithIdSuccess({id:action.id,res:res})];
        }),
        catchError((error) =>
          of(SuperAdminSettingsActions.saveAbilityError({ err: error }))
        )
      );
    })
  )
);
getAllAvailableModules$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.getAllAvailableModules),
    withLatestFrom(this.store.select(getAllAvailabeModules)),
    mergeMap(([_,stateData]) => {
      if(stateData){
        return of(SuperAdminSettingsActions.getAllAvailableModulesSuccess({res:stateData}));
      }else{
      return from(this.superAdminSettings.getAllAvailabeModules()).pipe(
        mergeMap((res:IAllModules) => {
          return [SuperAdminSettingsActions.getAllAvailableModulesSuccess({res:res})];
        }),
        catchError((error) =>
          of(SuperAdminSettingsActions.saveAbilityError({ err: error }))
        )
      );
      }
    })
  )
);

getAllAvailableCategoriesForIntegration$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.getAllCategoriesForIntegration),
    switchMap((_) => {
      return from(this.superAdminSettings.getAllCategoriesForIntegration()).pipe(
        switchMap((res) => {
          return [SuperAdminSettingsActions.getAllCategoriesForIntegrationSuccess({res:res.categories})];
        }),
        catchError((error) =>
          of(SuperAdminSettingsActions.saveAbilityError({ err: error }))
        )
      );
    })
  )
);

getAllAvailableTypesWithCategories$ = createEffect(() =>
  this.actions$.pipe(
    ofType(SuperAdminSettingsActions.getAllTypesWithCategory),
    switchMap((action) => {
      return from(this.superAdminSettings.getAllTypeWithCategories(action.category)).pipe(
        switchMap((res) => {
          return [SuperAdminSettingsActions.getAllTypesWithCategorySuccess({res:res})];
        }),
        catchError((error) =>
          of(SuperAdminSettingsActions.saveAbilityError({ err: error }))
        )
      );
    })
  )
);




}