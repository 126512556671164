<p-table class="row-expansion-cmp" [value]="rowTableData" [lazy]="true" (onLazyLoad)="loadItems($event)"
  [scrollable]="true" rowGroupMode="subheader" [groupRowsBy]="rowTableVar.groupBy" [dataKey]="rowTableVar.groupBy"
  [sortField]="rowTableVar.sortField" sortMode="single" (onRowExpand)="onRowExpand($event)"
  (onRowCollapse)="onRowCollapse($event)" responsiveLayout="stack" stateStorage="session"
  [stateKey]="sessionStorageName" scrollHeight="flex">

  <ng-template pTemplate="caption">

    <div class="">

      <div class="flex header-container align-items-center"
        [ngClass]="{'justify-content-right':showSelectButton,'justify-content-between':!showSelectButton}">
        <!-- filte pils -->

        <div [ngClass]="{'hide-containers':showSelectButton}"
          class="flex lg:flex-row filter-column-selector align-items-center">
          @if (!showSelectButton) {
          <div class="filter-buttons hide-buttons">
            <p-button [icon]="(showFilterPills ? 'pi pi-times p-button-rounded' : 'pi pi-filter p-button-rounded')" pRipple
              styleClass="p-button-secondary" (click)="showFilterPills = !showFilterPills"></p-button>
            </div>
          }
          <div class="" [ngClass]="{'hide-containers':showFilterPills}">
            <p-multiSelect (onChange)="refreshOrderedSeenOrderTableHeaderArray($event)"
              [(ngModel)]="seenOrderTableHeaderArray" [options]="orderTableHeaderArray" [style]="{maxWidth: '100%'}"
              optionLabel="header" [displaySelectedLabel]="false" dropdownIcon="pi pi-bars">
            </p-multiSelect>
          </div>
          <div [ngClass]="{'show-filter-chips': showFilterPills,'hide-containers':!showFilterPills}">
            <div class="flex flex-wrap chips-container">
              @for (pill of selectedFilterPillsArray; track pill?.label) {
              <p-chip (onRemove)="pillRemoved(pill)" [label]="pill.label" [pTooltip]="pill.tooltip" [removable]="pill.removeAction"
                styleClass="mx-2 my-1 custom-chip" tooltipPosition="top"></p-chip>
              }
            </div>
          </div>
        </div>

        <!-- table buttons  -->
        <div class="drop-down-buttons">
          <!-- drop down buttons -->

          <span style="position: relative;" class="refresh-icon" [ngClass]="{'refresh-icon-mobile': showSelectButton}">
            @if (autoRefreshBtnConfig.showRefreshIcon) {
            <i class="pi pi-refresh cursorPointer" id="refreshButton" (click)="autoRefreshBtnClicked();"
              [pTooltip]="(autoRefreshBtnConfig.newConsignmentCount > 0) ? 'Click to Refresh' : ''" tooltipPosition="bottom"></i>
            }

            @if (autoRefreshBtnConfig.showRefreshIcon) {
            <p-badge [value]="autoRefreshBtnConfig.newConsignmentCount"
              [ngClass]="{'blink-animate': autoRefreshBtnConfig.newConsignmentCount > 0}" class="mr-4 auto-refresh-badge"></p-badge>
            }
          </span>

          <div class="ref-sel-btns show-ref-sel-btns">
            @if (autoRefreshBtnConfig.showEnableBtn) {
            <p-toggleButton class="auto-refresh-btn" (onChange)="autoRefreshToggle($event)"
              [(ngModel)]="autoRefreshBtnConfig.checked" onLabel="A" offLabel="A" [onIcon]="'pi pi-refresh'"
              offIcon="pi pi-refresh"
              [pTooltip]="autoRefreshBtnConfig.checked ? 'Disable Auto Refresh' : 'Enable Auto Refresh'"
              tooltipPosition="bottom"></p-toggleButton>
            }

           
            @if (carriersMultiSelectBtnConfig) {
            <p-button (click)="op.toggle($event)" [ngClass]="{
                  'filter-btn-active':   (selectedCarriers?.length || selectedRoutePlans?.length || selectedDeliveryCompletionTypes?.length || selectedReturnType),
                  'filter-btn-inactive':!(selectedCarriers?.length || selectedRoutePlans?.length || selectedDeliveryCompletionTypes?.length || selectedReturnType)
                  }" styleClass="filter-btn" icon="pi pi-filter">
            </p-button>
            }
            @if (autoRefreshBtnConfig.showAlertNotificationBtn) {
            <span class="extend-button-onHover">
              <i class="pi pi-send"></i>
              <p-button class="alert-button extend-button-onHover__button-to-extend"
                (click)="alertNotificationBtnClicked();">Notification</p-button>
              </span>
            }
          </div>
          <!-- <div class="menu-button hide-buttons" *ngIf="!showFilterPills">
          <p-button [icon]="(showSelectButton ? 'pi pi-times' : 'pi pi-bars')" pRipple
            styleClass="p-button-secondary"
            (click)="showSelectButton = !showSelectButton"
          ></p-button>
        </div> -->
        </div>
      </div>

    </div>

    <!-- <p-multiSelect [options]="orderTableHeaderArray" [(ngModel)]="seenOrderTableHeaderArray" optionLabel="header"
    selectedItemsLabel="{0} columns selected" [style]="{maxWidth: '100%'}" placeholder="Choose Columns"
  (onChange)="refreshOrderedSeenOrderTableHeaderArray($event)"></p-multiSelect> -->
  </ng-template>

  <ng-template pTemplate="header">
    @if (showHeader || rowExpandedCount > 0) {
    <tr class="expand-row-header" style="display: flex; justify-content: space-between; align-items: center;">
      @for (columnHeaderItemField of getOrderedColumnSeenArray(); track columnHeaderItemField) {
      <th style="width: 100%; display: flex; justify-content: left;align-items: center; height: 50px;">
        <div class="order-table-column-header-item">
          @if (orderTableHeaderObj[columnHeaderItemField['field']]['columnDisplayName']) {
          <div class="order-table-column-name-text">
            {{ orderTableHeaderObj[columnHeaderItemField['field']]['columnDisplayName'] | translate }}
            @if (orderTableHeaderObj[columnHeaderItemField['field']]['isFilterable'] &&
            (orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] === 'multiSelectFromOptions')) {
            <p-columnFilter [field]="orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']" [showAddButton]="false"
              [showApplyButton]="false" [showMatchModes]="false" [showOperator]="false" display="menu"
              matchMode="multiSelectFromOptions">
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold"></span>
                </div>
              </ng-template>
              <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                <p-multiSelect [ngModel]="value"
                  [options]="orderTableHeaderObj[columnHeaderItemField['field']]['availableOptionsArray']"
                  placeholder="{{orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuHeaderText'] | translate}}"
                  (onChange)="filter($event.value)" optionLabel="name" display="chip">
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
            }
            @if (orderTableHeaderObj[columnHeaderItemField['field']]['isFilterable'] &&
            (orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] === 'dateRange')) {
            <p-columnFilter [field]="orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']" type="date"
              matchMode="dateRange" display="menu" [showMatchModes]="false" [showClearButton]="false" [showApplyButton]="false"
              [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">Date Range</span>
                </div>
              </ng-template>
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-calendar #calendar [ngModel]="value" (onSelect)="filter(calendar.value)"
                  (onInput)="setup(calendar.value, orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'])"
                  styleClass="p-column-filter" placeholder="Start Date and End Date" selectionMode="range"
                  inputId="range" [readonlyInput]="false" dateFormat="dd-mm-yy"></p-calendar>
              </ng-template>
            </p-columnFilter>
            }
            @if (orderTableHeaderObj[columnHeaderItemField['field']]['isFilterable'] &&
            (orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuType'] === 'groupedMultiSelectFromOptions'))
            {
            <p-columnFilter [field]="orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']"
              matchMode="groupedMultiSelectFromOptions" display="menu" [showMatchModes]="false" [showApplyButton]="false"
              [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold"></span>
                </div>
              </ng-template>
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [options]="orderTableHeaderObj[columnHeaderItemField['field']]['availableOptionsArray']" [group]="true"
                  [ngModel]="value" displaySelectedLabel="false" optionLabel="name"
                  placeholder="{{orderTableHeaderObj[columnHeaderItemField['field']]['filterMenuHeaderText'] | translate}}"
                  scrollHeight="250px" (onChange)="filter($event.value)" display="chip">
                  <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                      <span>{{ group.name }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
            }
          </div>
          }
        </div>
      </th>
      }
    </tr>
    }
  </ng-template>


  <ng-template let-expanded="expanded" let-expansionData let-toggle pTemplate="groupheader">
    <tr style="display: flex; justify-content: space-between; align-items: center;"
      [ngClass]="{'selected-row-to-highlight': expansionData[rowTableVar.groupBy]==currentGroupById}">
      <td class='rowgroup-header-field grid1'>
        <button [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" [pRowToggler]="expansionData"
          class="p-button-text p-button-rounded p-button-plain p-mr-2 expand-row-btn" pButton pRipple type="button">
        </button>
        <div class="avatar-image">
          @if (expansionData.avtarId) {
          <img [src]="assetService.getRegisteredFaceThumbnail(expansionData.avtarId)" alt="img" height="42"
            style="vertical-align: middle;" width="42" />
          }
        </div>

        <div class="delivery-person-details">
          @if(expansionData?.creationType==='scheduler')
          {
            <i class="pi pi-clock" pTooltip="Scheduled" tooltipPosition="top"></i>
          } @else {
            <i class="pi pi-directions" pTooltip="Ad-hoc" tooltipPosition="top"></i>
          }
          {{ expansionData.rowHeader ? expansionData.rowHeader : '-' }}
          @if(pageName === 'progress'){
            @if (expansionData?.isOffline === false) {
          <span class="dot online" pTooltip="Vehicle Status: Online {{expansionData?.sensorLastUpdated}}" tooltipPosition="top">
            <input pTooltip="Vehicle Status: Online {{expansionData?.sensorLastUpdated}}" tooltipPosition="top" tooltipEvent="focus"
              type="text" class="hidden-input" readonly />
          </span>
          }
          @else if (expansionData?.isOffline === true) {
          <span class="dot offline" pTooltip="Vehicle Status: Offline {{expansionData?.sensorLastUpdated}}" tooltipPosition="top">
            <input pTooltip="Vehicle Status: Online {{expansionData?.sensorLastUpdated}}" tooltipEvent="focus"
              tooltipPosition="top" type="text" class="hidden-input" readonly />
          </span>
          }
          }
          @if (expansionData?.isNewDelivery) {
            <p-chip styleClass="ml-1 custom-chip" label="New"></p-chip>
          }
          <br>
          <span class="font-weight-normal subtitle">
            {{ expansionData.rowSubHeader ? expansionData.rowSubHeader : '-' }}
          </span>
          <div class="formattedDate">{{expansionData?.created ? (expansionData?.created | date:"dd/MM/yy" ): ""}}
            <span>{{expansionData?.created ?
              (expansionData?.created | date:"shortTime" ): ""}}
            </span>
          </div>
          @if (expansionData?.routePlanNames && expansionData?.routePlanNames?.length > 0) {
          <span class="font-weight-normal subtitle">
            <p-chip [ngStyle]="{'margin-top': '5px'}" class="small-chip" [pTooltip]="'Route Plan: '+expansionData?.routePlanNames"
              tooltipPosition="top">
              <span class=" text-xs">{{ expansionData?.routePlanNames[0] }}</span>
            </p-chip>
          </span>
          }
          @for (addCarrierInfo of getAddCarrInfo(expansionData?.deliveryData?.additionalCarrierInfo); track
          addCarrierInfo?.label)
          {
          <div>
            <span class="font-weight-normal subtitle">
              <p-chip [ngStyle]="{'margin-top': '5px'}" class="small-chip"
                [pTooltip]="(addCarrierInfo.label | titlecase)+ ': '+addCarrierInfo.value" tooltipPosition="top">
                <span class=" text-xs">{{ addCarrierInfo.value }}</span>
              </p-chip>
            </span>
          </div>
          }
        </div>

      </td>

      <td class="grid2" #timelineGrid>
        <div>
          <app-horizontal-custom-timeline [ngStyle]="{'width':'100%'}" [timelineOuterContainerWidth]="timelineOuterContainerWidth"
            [timelineData]="expansionData.horizontalTimeline"
            [timelineOuterLeftData]="expansionData.horizontalTimelineLeftGridContent"
            [timelineOuterRightData]="expansionData.horizontalTimelineRightGridContent"
            [etaDetails]="getEtaDetailsForTimeline" [timelineID]="expansionData.rowHeader"
            [pageName]="pageName" (emittSelectedNonComplianceEvent)="handleData($event,expansionData)"/>
        </div>
      </td>

      <td class="grid3">
        <div class="icons-wrapper">
          <div class="icons-main-div">
            <!-- upper row -->
            @if (showHeaderIcons.lastUpdated && latestTime(expansionData)) {
            <div class="latest-socket-time" pTooltip="Last Updated" tooltipPosition="top">
              {{ latestTime(expansionData) }}
            </div>
            }
            @if (showHeaderIcons.humidity || showHeaderIcons.temp || showHeaderIcons.boxStatus) {
            <div class="main-wrapper upper-row">
              <!--  Humidity icon & details -->
              @if (showHeaderIcons.humidity) {
              <div class="data-icons" pTooltip="Humidity" tooltipPosition="bottom" tooltipEvent='hover'>
                <img width="18px" height="18px" src="assets/asset-icons/humidity.png">
                <span class="subtitle font14">
                  {{ expansionData.humidity ? expansionData.humidity + '%' : '-' }}
                </span>
              </div>
              }
              <!--  coolant icon & details -->
              @if (showHeaderIcons.temp) {
              <div class="data-icons" [pTooltip]="showTempTooltip(expansionData)" tooltipPosition="bottom" tooltipEvent='hover'>
                <img width="18px" height="18px" src="assets/asset-icons/coolant-temperature-black.png">
                <span class="subtitle font14" [style]="'color:'+expansionData?.tempFontColor || 'gray'">
                  {{ expansionData.temp ? expansionData.temp + '°' : '-' }}
                </span>
              </div>
              }
              <!-- door icon & details -->
              @if (showHeaderIcons.boxStatus) {
              <div class="data-icons" [pTooltip]="_mmdModuleVar.boxStatusLabel" tooltipPosition="bottom" tooltipEvent='hover'>
                @if (!_mmdModuleVar.isMmdModule && (!expansionData.boxStatus || expansionData.boxStatus == '' ||
                expansionData.boxStatus == 'NA' || expansionData.boxStatus == 'Close')) {
                <img width="22px" height="18px" src="../../../../assets/asset-icons/box-close1.png">
                }
                @if (!_mmdModuleVar.isMmdModule && expansionData.boxStatus == 'Open') {
                <img width="22px" height="18px" src="../../../../assets/asset-icons/box-open2.png">
                }
                @if (_mmdModuleVar.isMmdModule && (!expansionData.boxStatus || expansionData.boxStatus == '' ||
                expansionData.boxStatus == 'NA' || expansionData.boxStatus == 'Close')) {
                <img width="22px" height="18px" src="assets/asset-icons/double-door.png">
                }
                @if (_mmdModuleVar.isMmdModule && expansionData.boxStatus == 'Open') {
                <img width="22px" height="18px" src="assets/asset-icons/double-door-open.png">
                }
                <span class="subtitle font14">
                  {{ expansionData.boxStatus ? expansionData.boxStatus : '-' }}
                </span>
                </div>
                }
                </div>
            }

            <!-- bottom row -->
            <div class="main-wrapper bottom-scroll prime-table-btn-set">
              <!-- location details -->
              <div class="p-text-bold p-ml-2 data-icons" pTooltip="Location">
                @if (showHeaderIcons.address) {
                <button pButton pRipple type="button" icon="pi pi-map-marker"
                  (click)="locOp.toggle($event); getAssetLocation(expansionData)"
                  class="p-button-rounded p-button-secondary   "></button>
                }

              

                <p-overlayPanel #locOp [style]="{width: '300px'}">
                  <h6><b>Carrier Location</b></h6>
                  <p class="addressFont"> {{ agentDetails.address }} </p>
                  <p class="subtitle"> {{ agentDetails.date }} </p>

                  @if (!agentDetails.address) {
                  <app-circular-loader></app-circular-loader>
                  }
                </p-overlayPanel>
              </div>

   <!--  Non-Compliance Events-->
   @if (showHeaderIcons.showComplianceEvents) {
    <div class="action-icons cursorPointer data-icons" id="model-copen-btn" pTooltip="Non-Compliance Events" tooltipPosition="bottom"
      tooltipEvent='hover' (click)="viewRouteBtnClicked(expansionData,showHeaderIcons)">
      <button pButton pRipple type="button" icon="pi pi-th-large" class="p-button-rounded p-button-secondary   "></button>
    </div>
    }

              <!--  view map icon -->
              @if (showHeaderIcons.view) {
              <div class="action-icons cursorPointer data-icons" id="model-copen-btn" pTooltip="Order Route" tooltipPosition="bottom"
                tooltipEvent='hover' (click)="viewRouteBtnClicked(expansionData,false)">
                <button pButton pRipple type="button" icon="pi pi-map" class="p-button-rounded p-button-secondary   "></button>
              </div>
              }

              <!-- force completiom icon -->
              @if (showHeaderIcons.forceStopRoundTrip) {
              <div class="action-icons data-icons cursorPointer" pTooltip="Manual Close">
                <button pButton pRipple type="button" icon="pi pi-check-circle" class="p-button-rounded p-button-danger   "
                  (click)="stopRoundTrip.toggle($event)"></button>
                <!-- force completion details -->
                <p-overlayPanel #stopRoundTrip>
                  <h6 style="font-size: 16px;font-weight: 600;line-height: 22px;">
                    This will complete all in progress deliveries, and you will not be able to revert back to previous
                    state.
                  </h6>
                  <h6 style="color:#80675d;">
                    Reason for manually closing the trip.
                  </h6>
                  <ng-template pTemplate>
                    <div id="force-complete-container" class="column-flex" style="justify-content: flex-start;">
                      <textarea class="force-complete-textarea" pInputTextarea [(ngModel)]="stopRoundTripReason"></textarea>
                      <div>
                        <p-button icon=" pi pi-check" styleClass="p-button-rounded" class="force-complete-button"
                          (onClick)="stopRoundTrip.hide()" (onClick)="cancelTrip(expansionData)" tooltipPosition="top"
                          [pTooltip]="'Cancel round trip'">
                        </p-button>
                      </div>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
              }

              <!-- edit icon  -->
              @if (canUpdateState() && showHeaderIcons.edit && expansionData.isEditable) {
              <div id="model-copen-btn cursorPointer" class="action-icons data-icons cursorPointer"
                (click)="editBtnClicked(expansionData)" pTooltip="Edit">
                <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-secondary   "></button>
                </div>
              }

              <!-- download option -->
              @if (showHeaderIcons.report) {
              <div class="action-icons cursorPointer data-icons" pTooltip="Download" tooltipPosition="bottom"
                tooltipEvent='hover' (click)="op2.toggle($event)">
                <button pButton pRipple type="button" icon="pi pi-cloud-download"
                  class="p-button-rounded p-button-secondary   "></button>
                <p-overlayPanel #op2>
                  <h6>
                    Please Select a Format
                  </h6>
                  <ng-template pTemplate>
                    <div class="flex justify-content-center align-items-center">
                      <p-button icon="pi pi-chart-line" class="mr-3 mb-3 p-button-sm"
                        (onClick)="downloadReport(expansionData, 'pdf')" tooltipPosition="bottom"
                        [pTooltip]="'Click to Download PDF Report'">
                      </p-button>
                      <p-button icon="pi pi-file-excel" class="mr-3 mb-3 p-button-sm"
                        (onClick)="downloadReport(expansionData, 'xlsx')" tooltipPosition="bottom"
                        [pTooltip]="'Click to Download Excel Report'">
                      </p-button>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>
              }

              <!-- QR code -->
              @if (showHeaderIcons.qrCode) {
              <div class="action-icons cursorPointer data-icons" pTooltip="QR Code" tooltipPosition="bottom" tooltipEvent='hover'
                (click)="viewQrCodeBtnClicked(expansionData)">
                <button pButton pRipple type="button" icon="pi pi-qrcode" class="p-button-rounded p-button-secondary   "></button>
              </div>
              }

            </div>
            <!--
            <div class="icon-container" *ngIf="!expansionData.isEditable">
              <span></span>
            </div> -->

          </div>
        </div>
      </td>
    </tr>
  </ng-template>


  <ng-template pTemplate="rowexpansion" let-data let-rowIndex="rowIndex" let-expanded="expanded">
    <tr class="expanded-row" style="display: flex; justify-content: space-between; align-items: center;"
      [ngClass]="{'selected-row-to-highlight': data['orderId'] === highlightedOrderId}">

      @for (columnHeaderItemField of getOrderedColumnSeenArray(); track columnHeaderItemField) {
      <td class="order-table-cell" style="width: 100%; display: flex; justify-content: left;">
        @if (orderTableHeaderObj[columnHeaderItemField['field']]['isContentArray'] &&
        !orderTableHeaderObj[columnHeaderItemField['field']]['isIconType']) {
        <div class="order-details-container">
          <div class="order-items-list-container">
            @for (item of data[columnHeaderItemField['field']]; track item) {
            <li class="order-items-details">
              @if(item?.length !== undefined) {
              <span>
                {{ item }}
              </span>
              }
              @if (item?.length === undefined) {
              <span>
                @for (nestedItem of item; track nestedItem?.data) {
                <span [pTooltip]=" nestedItem.toolTipData" tooltipEvent="hover" tooltipPosition="right">
                  {{ nestedItem.data }}
                </span>
                }
                </span>
                }
                </li>
                }
                </div>
                </div>
                }
        @if (!orderTableHeaderObj[columnHeaderItemField['field']]['isContentArray'] &&
        !orderTableHeaderObj[columnHeaderItemField['field']]['isIconType'] &&
        !orderTableHeaderObj[columnHeaderItemField['field']]['isDataClickedToMakeApiCall'] &&
        !orderTableHeaderObj[columnHeaderItemField['field']]['isUpdatable']) {
        <div
          (click)="data[columnHeaderItemField['field']] && checkIfValueIsNotRoundTripLabel(data[columnHeaderItemField['field']]) && orderTableHeaderObj[columnHeaderItemField['field']]['isClickable'] ?clickableDataIsClicked(data) : '' "
          [ngClass]="{'row-clickable-button': data[columnHeaderItemField['field']] && checkIfValueIsNotRoundTripLabel(data[columnHeaderItemField['field']]) && orderTableHeaderObj[columnHeaderItemField['field']]['isClickable'], 'custom-badge ' : orderTableHeaderObj[columnHeaderItemField['field']]['showCustomBadge'], 'hide-btn': !data[columnHeaderItemField['field']]}"
          [ngStyle]="orderStatusColorService.getTextAndBorderColor(data[columnHeaderItemField['field']], orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'])"
          class="order-details-container"
          [pTooltip]="showTooltip(orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'], data)"
          tooltipPosition="bottom"
          id="{{data[columnHeaderItemField['field']] && checkIfValueIsNotRoundTripLabel(data[columnHeaderItemField['field']]) && orderTableHeaderObj[columnHeaderItemField['field']]['isClickable'] ? 'row-clickable' : 'row-not-clickable'}}">
          {{ data[columnHeaderItemField['field']] ?
          orderTableHeaderObj[columnHeaderItemField['field']]['isNamingConventionReq'] ?
          (data[columnHeaderItemField['field']] | namingConvention) : data[columnHeaderItemField['field']] : '-' }}
          </div>
          }
          <!-- order status dropdown -->
        @if (!orderTableHeaderObj[columnHeaderItemField['field']]['isContentArray'] &&
        !orderTableHeaderObj[columnHeaderItemField['field']]['isIconType'] &&
        !orderTableHeaderObj[columnHeaderItemField['field']]['isDataClickedToMakeApiCall'] &&
        orderTableHeaderObj[columnHeaderItemField['field']]['isUpdatable']) {
        <div
          (click)="disableDelStatusRadioBtn=false;orderTableHeaderObj[columnHeaderItemField['field']]['isClickable'] ?clickableDataIsClicked(data) : '' "
          [ngClass]="{'row-clickable-button': orderTableHeaderObj[columnHeaderItemField['field']]['isClickable'], 'custom-badge ' : orderTableHeaderObj[columnHeaderItemField['field']]['showCustomBadge']}"
          [ngStyle]="{'padding':'0px'}" class="order-details-container">
          @if (data['orderId'] === orderTableHeaderObj[columnHeaderItemField['field']]['dropdownContionalText']) {
          <button (click)="op.toggle($event); isCustoToolTipSetting = true" [label]="CONSTANT_LABEL.IN_PROGRESS"
            class="custom-button custom-badge" icon="pi pi-chevron-down" pButton type="text">
          </button>
          }
          @if (canEdit[data['id']]) {
          <button (click)="op.toggle($event); isCustoToolTipSetting = false"
            [label]="data[columnHeaderItemField['field']] ? orderTableHeaderObj[columnHeaderItemField['field']]['isNamingConventionReq'] ? (data[columnHeaderItemField['field']][columnHeaderItemField['field']] | namingConvention) : data[columnHeaderItemField['field']][columnHeaderItemField['field']] : '-'"
            class="custom-button" icon="pi pi-chevron-down" pButton type="text"
            [ngStyle]="orderStatusStyle(data[columnHeaderItemField['field']][columnHeaderItemField['field']],orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'])">
          </button>
          }
          @if (!canEdit[data['id']] && data[columnHeaderItemField['field']] &&
          data[columnHeaderItemField['field']][columnHeaderItemField['field']] && !(data[columnHeaderItemField['field']]
          === orderTableHeaderObj[columnHeaderItemField['field']]['dropdownContionalText'])) {
          <div
            [ngClass]="{'row-clickable-button': orderTableHeaderObj[columnHeaderItemField['field']]['isClickable'], 'custom-badge ' : orderTableHeaderObj[columnHeaderItemField['field']]['showCustomBadge']}"
            [ngStyle]="orderStatusStyle(data[columnHeaderItemField['field']][columnHeaderItemField['field']],orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'])"
            class="order-details-container" style="white-space: nowrap !important;">
            {{ data[columnHeaderItemField['field']] ?
            orderTableHeaderObj[columnHeaderItemField['field']]['isNamingConventionReq'] ?
            (data[columnHeaderItemField['field']][columnHeaderItemField['field']] | namingConvention) :
            data[columnHeaderItemField['field']][columnHeaderItemField['field']] : '-' }}
          </div>
          }
          @if (data[columnHeaderItemField['field']] && data[columnHeaderItemField['field']]['statusDate']) {
          <p class="statusDate">{{ data[columnHeaderItemField['field']]['statusDate'] }}</p>
          }
          <p-overlayPanel #op (onShow)="radioButtonsData=data?.[columnHeaderItemField?.['field']]?.['selectedOptions']">
            <ng-template pTemplate>
              <div class="delivery_status_btn_overlay">
                @if (!isCustoToolTipSetting) {
                <div>
                  @for (option of data[columnHeaderItemField['field']]['options']; track option?.label) {
                  <div class="p-field-radiobutton">
                    <p-radioButton [disabled]="disableDelStatusRadioBtn"
                      (onClick)="disableDelStatusRadioBtn=true;onRadioBtnClick(option.status, data)"
                      [(ngModel)]="radioButtonsData" [inputId]="option.status" [value]="option" name="option">
                    </p-radioButton>
                    <label [for]="option.status" style="margin-top:0">{{ option.label }}</label>
                  </div>
                  }
                </div>
                }
                @if (disableDelStatusRadioBtn) {
                <div>
                  <app-circular-loader></app-circular-loader>
                </div>
                }
              </div>
              @if (isCustoToolTipSetting) {
              <div>
                <div class="p-field-radiobutton">
                  <p-radioButton (onClick)="customRadiotButtonClicked(data); " [(ngModel)]="selectedOption"
                    [inputId]="'customTooltip'" [value]="'completeRoundTrip'">
                  </p-radioButton>
                  <label [for]="'customTooltip'">Complete Round Trip</label>
                </div>
              </div>
              }
            </ng-template>
          </p-overlayPanel>
        </div>
        }
        <!-- order status dropdown -->
        @if (!orderTableHeaderObj[columnHeaderItemField['field']]['isContentArray']
        && !orderTableHeaderObj[columnHeaderItemField['field']]['isIconType']
        && orderTableHeaderObj[columnHeaderItemField['field']]['isDataClickedToMakeApiCall']) {
        <div (click)="dataClickedToMakeApiCall(data)" class="cursorPointer">
          {{ data[columnHeaderItemField['field']] }}
        </div>
        }
        @if (data[columnHeaderItemField['field']] && orderTableHeaderObj[columnHeaderItemField['field']]['isIconType']
        && !orderTableHeaderObj[columnHeaderItemField['field']]['isDataClickedToMakeApiCall']) {
        <div
          [ngClass]="{'showGenericWarningConfirmationPopupBtn': (orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'])== 'delete' ? true : false, 'cursorPointer':  true}">
          <!-- (click)="emitIconClickedData(data, orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName']);" -->
          @if (orderTableHeaderObj[columnHeaderItemField['field']]['imageSource']) {
          <div (click)="confirm($event, data, orderTableHeaderObj[columnHeaderItemField['field']]['filterFieldName'])"
            [ngClass]="orderTableHeaderObj[columnHeaderItemField['field']]['imageSource']"></div>
          }
          @if (!orderTableHeaderObj[columnHeaderItemField['field']]['imageSource']) {
          <div class="noCursorPointer">-</div>
          }
          </div>
          }
          </td>
      }

    </tr>
  </ng-template>

</p-table>

<p-confirmPopup [key]="'tableRowExpConfitmPopup'"></p-confirmPopup>
<p-toast key="tableRowExpNotification"></p-toast>


<p-overlayPanel [dismissable]="false" styleClass="filter-panel-container" showCloseIcon="true"
[showClear]="true" #op>
<div class="filter-container" [ngClass]="{'filter-container-height': manageFilterPanelHeight}">
  @if (showRoutePlansFilter) {
  <span>
    <p class="filter-wrapper-title">TD / Route Plans :</p>
    <p-treeSelect [(ngModel)]="selectedRoutePlans" (ngModelChange)="filterByRoutePlans($event)" appendTo="body"
      [options]="groupedRoutePlansArray" (onShow)="manageFilterPanelHeight=true" (onHide)="manageFilterPanelHeight=false"
      display="chip" [metaKeySelection]="false" [showClear]="true" [filter]="true" selectionMode="checkbox"
      class="route-plan-filter" placeholder="TD / Route Plans">
      <ng-template pTemplate="clearicon">
        <i class="pi pi-times-circle"></i>
      </ng-template>
    </p-treeSelect>
    </span>
  }
  @if (carriersMultiSelectBtnConfig) {
  <span [style]="{float: 'right'}">
    <p class="filter-wrapper-title">Carriers :</p>
    <p-multiSelect [options]="carriersMultiSelectBtnConfig" [(ngModel)]="selectedCarriers"
      class="carriers-multi-select route-plan-filter" [defaultLabel]="'Carriers'"
      (onPanelShow)="manageFilterPanelHeight=true" (onPanelHide)="manageFilterPanelHeight=false; carriersChange()" optionLabel="name"
      filterPlaceHolder="Select All" [showClear]="true" [filter]="true" (onChange)="isCarriersChanged = true;">
      <ng-template pTemplate="clearicon">
        <i class="pi pi-times-circle" (click)="isCarriersChanged = true;selectedCarriers=[];carriersChange()"></i>
        </ng-template>
        </p-multiSelect>
        </span>
        }
        @if (showDeliveryCompletionTypesFilter()) {
        <span [style]="{float: 'right'}">
          <p class="filter-wrapper-title">Delivery Completion Type :</p>
          <p-multiSelect [options]="deliveryCompletionTypes" [(ngModel)]="selectedDeliveryCompletionTypes" optionValue="value"
            class="carriers-multi-select route-plan-filter" [defaultLabel]="'Select'" optionLabel="label"
            filterPlaceHolder="Select All" [showClear]="true" [filter]="true" (onPanelHide)="deliveryCompletionTypesChange()"
            (onChange)="isDeliveryCompletionTypeChanged = true;">
            <ng-template pTemplate="clearicon">
              <i class="pi pi-times-circle"
                (click)="isDeliveryCompletionTypeChanged=true;selectedDeliveryCompletionTypes=[];deliveryCompletionTypesChange()"></i>
      </ng-template>
    </p-multiSelect>
  </span>
  }
  @if (showReturnTypesFilter()) {
  <span [style]="{float: 'right'}">
    <p class="filter-wrapper-title">Return Type :</p>
    <p-dropdown [options]="returnTypes" [(ngModel)]="selectedReturnType" optionValue="value" display="chip"
      class="carriers-multi-select route-plan-filter" optionLabel="label" placeholder="Select" [showClear]="true"
      [filter]="true" (onChange)="returnTypesChange()">
      <ng-template pTemplate="clearicon">
        <i class="pi pi-times-circle" (click)="selectedReturnType=null;returnTypesChange()"></i>
      </ng-template>
    </p-dropdown>
  </span>
  }
  <p-button
    [disabled]="!(selectedCarriers?.length || selectedRoutePlans?.length || selectedDeliveryCompletionTypes?.length || selectedReturnType)"
    label="Clear"
    styleClass="clear-filter-btn" (click)="clearFilters()"></p-button>
</div>
</p-overlayPanel>