import { createAction, props } from "@ngrx/store";
import {Account, AccountLicenceIfoState} from './login.reducer'

export const validateLogin = createAction(
  "[Login user API] Validate creds to login",
  props<{ username: string; password: string; language: string }>()
);

export const loginSuccess = createAction(
  "[Effects Login API] on successful login",
  props<{ response: any }>()
);

export const loginFailure = createAction(
  "[Effects Login API] on error while logging in",
  props<{ err: string }>()
);

export const setToken = createAction(
  "[Login user] on logging in set token",
  props<{ token: string }>()
);

export const setAccount = createAction(
  "[Login user] on logging in set account details",
  props<{ accountDetails: Account }>()
);

export const setStatusToLoading = createAction(
  "[Effects Login] update staus to loading when api call is made"
);

export const userLoggedOff = createAction(
  "[Any user] on log out"
)

export const userAuthenticated = createAction(
  '[Effects API] on successful login updating user to authenticated'
)

export const setAccountLicenceAlertInfo = createAction(
    '[Account Licence] on license info update',
    props<{accountLicenceInfoState: AccountLicenceIfoState }>()
)

export const resetPreviousLoginResult = createAction(
  "[Login user] before login reset previous login result",
);

export const getSessionTimeoutTime =  createAction(
  '[Load Token Expiry time] from session storage ',
)
export const getSessionTimeoutTimeSuccess =  createAction(
  '[Load Token Expiry time] on success from session storage',
  props<{ x: number }>()
)

export const getiotZenServerVersion =  createAction(
  '[IotZen Version] get iotzen version'
)

export const OnSuccessGetiotZenServerVersion =  createAction(
  '[IotZen Version] on success get iotzen version',
  props<{ res:{version:string} }>()
)

export const OnFailureGetiotZenServerVersion =  createAction(
  '[IotZen Version] on failure get iotzen version',
  props<{ error: string  }>()
)


export const checkKioskSubscription = createAction(
  "[Effects Login API] on successful login check kiosk subscription"
);

export const OnSuccessCheckKioskSubscription = createAction(
  "[Effects Login API] on success of checking kiosk subscription",
  props<{ response }>()
);

export const isBannerVisibleForInternetConfig = createAction(
  "[Effects Login API] is banner visible for internet config",
  props<{ value:boolean }>()
);

export const manageBannerVisibleForPrinterError = createAction(
  "[Effects Login API] is banner visible for internet config",
  props<{ data }>()
);

export const getResetPasswordLink = createAction(
  "[Effects Login API] get reset password link",
  props<{ username: string }>()
);

export const resetPasswordLinkSuccess = createAction(
  "[Login user] reset password link success",
  props<{ response }>()
);

export const resetPasswordLinkFailure = createAction(
  "[Login user] reset password link failure",
  props<{ error }>()
);