import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';


export const selectSockets = (state: AppState) => state.sockets;

export const selectGPS = createSelector(
  selectSockets,
  (sockets) => sockets.gps
);

export const getGPSSocketData = createSelector(
  selectGPS,
  (gps) => gps.res
);

export const getGPSSocketError = createSelector(
  selectGPS,
  (gps) => gps.err
);

export const selectLMDLiveStats = createSelector(
  selectSockets,
  (sockets) => sockets.lmdLiveStatus
);

export const getLmdLiveStatsData = createSelector(
  selectLMDLiveStats,
  (lmdLive) => lmdLive.res
);

export const getLmdLiveStatsError = createSelector(
  selectLMDLiveStats,
  (lmdLive) => lmdLive.err
);

export const getConnectedNameSpaces = createSelector(
  selectSockets,
  (sockets) => sockets.connectedNameSpaces
);

