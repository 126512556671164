import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges } from '@angular/core';
import { IComplianceEvent } from '../consignment-event-geofence-map/consignment-event-geofence-map.component';
import { DateFormatterService } from '../../../services/dateformat';
import moment from 'moment';
import { CONSTANT } from '../../../config/constant';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { LoaderComponent } from '../loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { isMobileViewSelector } from '../../../state/Assets/assets.selector';
import { clearEventsDataForAllVehicles, getCarGpsHistoricalData, getEventsForVehicle, getEventsSubTypesForVehicle } from '../../../state/Fleet/fleet.action';

const DELIVERY_STATUS_RETURNING = CONSTANT.DELIVERY_STATUS.RETURNING;
const DELIVERY_STATUS_COMPLETED = CONSTANT.DELIVERY_STATUS.COMPLETED;


@Component({
  selector: 'app-non-compliance-events-table',
  standalone: true,
  imports: [
    OverlayPanelModule,
    MultiSelectModule,
    TableModule,
    PaginatorModule,
    DropdownModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ButtonModule,
    TooltipModule,
    LoaderComponent,
    TranslateModule
  ],
  templateUrl: './non-compliance-events-table.component.html',
  styleUrl: './non-compliance-events-table.component.scss'
})
export class NonComplianceEventsTableComponent implements OnChanges, OnInit, OnDestroy {
  currentPageComplianceEvents = signal<any>([])

  @Input() eventsSubTypes
  @Input() agentDetails;
  @Input() selectedOrderDeliveryGroup;
  @Input() temperatureEvents
  @Input() selectedNonComplianceEvent

  subTypes = signal<string[]>([]);
  selectedSubType = signal<string[]>([]);

  @Output() emittGetEventAction: EventEmitter<any> = new EventEmitter();
  @Output() emittCurrentEvent: EventEmitter<any> = new EventEmitter();



  pagination = signal<Record<string, number>>({
    'currentPage': 1,
    'totalNoOfRecords': 0,
    'recordsPerPage': 7,
    'pageCountValue': 0,
  });

  isMobileView$ = this.store.pipe(select(isMobileViewSelector));


  constructor(
    public dateFormatter: DateFormatterService,
    private store: Store
  ) { }
  ngOnDestroy(): void {
    this.onViewChangeOfEventsDialog(true)
  }
  ngOnInit(): void {
    this.showTemperatureComplianceEventsDialog()
  }


  ngOnChanges(changes: SimpleChanges) {
    try {
      for (const propName of Object.keys(changes)) {
        const change = changes[propName];

        if (propName === 'eventsSubTypes') {
          if (change.currentValue !== change.previousValue) {
            this.updateEventsSubTypes()
          }
        }
        if (propName === 'temperatureEvents') {
          if (change.currentValue !== change.previousValue) {
            this.updateTemperatureEvents()

          }
        }
        if (propName === 'selectedOrderDeliveryGroup') {
          if (change.currentValue !== change.previousValue) {
            this.onViewChangeOfEventsDialog(false)
          }
        }
        if (propName === 'selectedNonComplianceEvent') {
          if (change.currentValue !== change.previousValue) {
            console.log(this.selectedNonComplianceEvent, 'this.selectedNonComplianceEvent')
            if (this.selectedNonComplianceEvent.name) {
              this.selectedSubType.set([this.selectedNonComplianceEvent.name])
              this.applyEventSubTypesFilter(this.selectedSubType())
            }
          }
        }

      }
    } catch (e) {
      console.error(e);//
    }
  }

  updateEventsSubTypes() {
    this.subTypes.set(this.eventsSubTypes?.[this.agentDetails?._id]?.find(({ type }) => type === 'Temperature')?.subType || [])
  }

  // dateFormator(date, format?) {
  //   let dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
  //   if (format) {
  //     if (format === 'date') {
  //       dateFormate = moment(date).format('MMMM Do YYYY, h:mm a');
  //     } else if (format === 'time') {
  //       dateFormate = moment(date).format('h:mm:ss a');
  //     } else if (format === 'lll') {
  //       dateFormate = moment(date).format('LLL');
  //     }
  //   }
  //   return dateFormate;
  // }

  currentNonComplianceEvent = signal<IComplianceEvent | null>(null);
  triggerRowClick(complianceEvent: IComplianceEvent) {
    // const timeValue = this.dateFormator(complianceEvent?.generatedTimeStamp, 'lll');
    // const temperatureValue = complianceEvent?.sensorData.temperature

    this.currentNonComplianceEvent.set(complianceEvent);
    this.emittCurrentEvent.emit(complianceEvent)


    // const modTempSeries = {
    //   ...(this.varyingEChartGraphData?.series?.[0] || {}),
    //   markPoint: {
    //     label: {
    //       fontSize: 11,
    //       color: 'white'
    //     },
    //     itemStyle: {
    //       color: '#4B6D7E',
    //     },
    //     data: [
    //       { coord: [timeValue, temperatureValue], symbol: 'pin', symbolSize: 40, value: 'O' },
    //     ],
    //   },

    // }

    // const temp: any = {
    //   ...this.varyingEChartGraphData,
    //   series: [modTempSeries],
    // }
    // this.eChartGraphDataForEvents = temp
    // this.eChartGraphDataForAll = this.isEventFocusedView() ? this.eChartGraphDataForAll : this.varyingEChartGraphData

    // let marker = L.marker({ lat: complianceEvent?.coordinates[0], lng: complianceEvent?.coordinates[1] })
    // const icon = divIcon({
    //   html: `<div></div>`,
    //   className: '',
    //   iconSize: [40, 40]
    // });
    // marker.setIcon(icon);
    // marker.bindPopup(
    //   getPopUpHeader(
    //     'Sensor stats', '',
    //     timeValue) +
    //   showPopupData([
    //     { label: 'Range : ', value: complianceEvent.subType },
    //     { label: 'Temp in °C : ', value: temperatureValue?.toFixed(2) },
    //   ])
    //   , { maxWidth: 1000, offset: [0, -8] });
    // marker.addTo(this.map);
    // marker.openPopup()

    // this.map?.panTo({ lat: complianceEvent?.coordinates[0], lng: complianceEvent?.coordinates[1] });
    // setTimeout(() => {
    //   this.map?.setZoom(17);
    // }, 1000);
  }

  showLoader
  applyEventSubTypesFilter(subTypes: Array<string>) {
    console.log(subTypes, this.selectedSubType())
    const pagination = this.pagination()
    pagination.currentPage = 1;
    this.pagination.set(pagination)

    this.showLoader = true;
    const subType = (subTypes || []).sort().join(',');
    const { startDate, endDate } = this.getStartAndEndDate(false);

    this.recieveGetEventAction({
      vehicleId: this.agentDetails?._id,
      limit: this.pagination().recordsPerPage,
      skip: this.pagination().recordsPerPage * (this.pagination().currentPage - 1),
      subType,
      clearData: true,
      startDate,
      endDate,
    })
  }

  updateTemperatureEvents() {
    const pagination = this.pagination()
    pagination.totalNoOfRecords = this.temperatureEvents?.[this.agentDetails?._id]?.count;
    this.pagination.set(pagination)

    this.showLoader = false
    if (pagination.totalNoOfRecords >= 0) {
      this.currentPageComplianceEvents.set(this.temperatureEvents?.[this.agentDetails?._id]?.data?.[this.pagination().currentPage] || this.currentPageComplianceEvents());
    }
  }


  getStartAndEndDate(isGraph: boolean) {
    const onRoadLogisticsData = this.onRoadLogisticsData();

    let sKey = isGraph ? 'startDate' : 'startDateForGettingEvents';
    let eKey = isGraph ? 'endDate' : 'endDateForGettingEvents';

    let startDate = onRoadLogisticsData[sKey];
    let endDate = onRoadLogisticsData[eKey];
    if (this.onRoadLogisticsData().forwardLogistics.isChecked != this.onRoadLogisticsData().reverseLogistics.isChecked) {
      const type = this.onRoadLogisticsData().forwardLogistics.isChecked ? 'forwardLogistics' : 'reverseLogistics'
      startDate = onRoadLogisticsData[type]['startDateValue'] || startDate;
      endDate = onRoadLogisticsData[type]['endDateValue'] || endDate;
    }

    return { startDate, endDate }
  }

  onRoadLogisticsData = signal<IOnRoadLogisticsData>({
    reverseLogistics: {
      startDateLabel: '', endDateLabel: '', startDateValue: 0, endDateValue: 0, isChecked: true
    },
    forwardLogistics: {
      startDateLabel: '', endDateLabel: '', startDateValue: 0, endDateValue: 0, isChecked: true
    },
    startDate: 0,
    endDate: 0,
    startDateLabel: '',
    endDateLabel: '',
    startDateForGettingEvents: 0,
    endDateForGettingEvents: 0
  })

  filterByOnRoadLogistics() {
    const orL = this.onRoadLogisticsData();
    ['reverseLogistics', 'forwardLogistics'].forEach((logisticsType) => {
      if (this.selectedOnRoadLogistic === this.onRoadLogisticsOptions[0].value) {
        orL[logisticsType].isChecked = true
      }
      else if (this.selectedOnRoadLogistic === logisticsType) {
        orL[logisticsType].isChecked = true
      } else {
        orL[logisticsType].isChecked = false
      }
    });
    this.onRoadLogisticsData.set(orL);
    this.showLoader = true;

    this.applyEventSubTypesFilter(this.selectedSubType());
    const { startDate, endDate } = this.getStartAndEndDate(false);
    this.store.dispatch(getCarGpsHistoricalData({
      startDate: startDate,
      endDate: endDate,
      id: this.agentDetails?._id,
      fI: 'sensorData,updated'
    }));

  }

  onViewChangeOfEventsDialog(isClear: boolean) {
    this.handleOnRoadLogisticsData(isClear);
  }

  getDateConvertor(date) {
    // let cDate = this.dateFormatter.convertDateToSelectedTimezone(date, 'DD/MM/YY');
    // return cDate +'  '+ this.dateFormatter.getFormattedTimeSlotForSingleTime(date)

   return  this.dateFormatter.convertDateToSelectedTimezone(date, 'DD/MM/YY, h:mm a')
  }

  handleOnRoadLogisticsData(isClear: boolean) {
    let onRoadLogisticsData = this.onRoadLogisticsData()

    if (isClear) {
      onRoadLogisticsData = {
        reverseLogistics: {
          startDateLabel: '', endDateLabel: '', startDateValue: 0, endDateValue: 0, isChecked: true
        },
        forwardLogistics: {
          startDateLabel: '', endDateLabel: '', startDateValue: 0, endDateValue: 0, isChecked: true
        },
        startDate: 0,
        endDate: 0,
        startDateForGettingEvents: 0,
        endDateForGettingEvents: 0,
        startDateLabel: '',
        endDateLabel: ''
      }
    } else {
      const data = this.selectedOrderDeliveryGroup?.onRoadLogistics;

      ['reverseLogistics', 'forwardLogistics'].forEach((logisticsType) => {
        if (data?.[logisticsType]?.startDate) {
          onRoadLogisticsData[logisticsType].startDateLabel = this.getDateConvertor(data?.[logisticsType]?.startDate)
          onRoadLogisticsData[logisticsType].startDateValue = new Date(data?.[logisticsType]?.startDate).getTime()
        }
        if (data?.[logisticsType]?.endDate) {
          onRoadLogisticsData[logisticsType].endDateLabel = this.getDateConvertor(data?.[logisticsType]?.endDate)
          onRoadLogisticsData[logisticsType].endDateValue = new Date(data?.[logisticsType]?.endDate).getTime()
        }
      });



      let startDate: any = moment();
      let endDate: any = moment();

      const selectedOrderDeliveryGroup = this.selectedOrderDeliveryGroup
      if (selectedOrderDeliveryGroup && selectedOrderDeliveryGroup.status === DELIVERY_STATUS_COMPLETED) {
        if (selectedOrderDeliveryGroup.created) {
          startDate = moment(selectedOrderDeliveryGroup.created);
        }
        if (selectedOrderDeliveryGroup.endDate) {
          endDate = moment(selectedOrderDeliveryGroup.endDate);
        }
      } else if (selectedOrderDeliveryGroup?.status === DELIVERY_STATUS_RETURNING && selectedOrderDeliveryGroup?.deliveryOrderStatus?.length === 0) {
        if (selectedOrderDeliveryGroup.created) {
          startDate = moment(selectedOrderDeliveryGroup.created);
        }
        endDate = moment()
      } else {
        if (selectedOrderDeliveryGroup && selectedOrderDeliveryGroup.statusTimeLine && selectedOrderDeliveryGroup.statusTimeLine.pickedUp) {
          if (selectedOrderDeliveryGroup.created) {
            startDate = moment(selectedOrderDeliveryGroup.created);
          }
          endDate = moment()
        }
      }

      let startDateForGettingEvents: any = moment();
      const endDateForGettingEvents: any = this.getEndDateForEventsByDeliveryGrp(this.selectedOrderDeliveryGroup);

      if (this.selectedOrderDeliveryGroup?.statusTimeLine?.inProgress) {
        const result = this.findEarliestTimestamp(this.selectedOrderDeliveryGroup?.statusTimeLine?.inProgress);
        if (result?.timestamp) {
          startDateForGettingEvents = moment(result.timestamp)
        } else if (this.selectedOrderDeliveryGroup.created) {
          startDateForGettingEvents = moment(this.selectedOrderDeliveryGroup.created);
        }
      } else if (this.selectedOrderDeliveryGroup.created) {
        startDateForGettingEvents
          = moment(this.selectedOrderDeliveryGroup.created);
      }

      onRoadLogisticsData.startDateForGettingEvents = startDateForGettingEvents
      onRoadLogisticsData.endDateForGettingEvents = endDateForGettingEvents

      onRoadLogisticsData.startDate = startDate
      onRoadLogisticsData.endDate = endDate
      onRoadLogisticsData.startDateLabel = this.getDateConvertor(startDate)
      onRoadLogisticsData.endDateLabel = this.getDateConvertor(endDate);

      ['reverseLogistics', 'forwardLogistics'].forEach((logisticsType) => {
        onRoadLogisticsData[logisticsType].startDateLabel = onRoadLogisticsData[logisticsType].startDateLabel || onRoadLogisticsData.startDateLabel
        onRoadLogisticsData[logisticsType].startDateValue = onRoadLogisticsData[logisticsType].startDateValue || onRoadLogisticsData.startDate
        onRoadLogisticsData[logisticsType].endDateLabel = onRoadLogisticsData[logisticsType].endDateLabel || onRoadLogisticsData.endDateLabel
        onRoadLogisticsData[logisticsType].endDateValue = onRoadLogisticsData[logisticsType].endDateValue || onRoadLogisticsData.endDate
      });

    }
    this.onRoadLogisticsData.set(onRoadLogisticsData)

  }

  private getEndDateForEventsByDeliveryGrp(deliveryGrp: any) {
    let endDate = moment().add(5, 'm');
    if (deliveryGrp?.status) {
      endDate =
        deliveryGrp.status === DELIVERY_STATUS_COMPLETED
          ? deliveryGrp.endDate
            ? moment(deliveryGrp.endDate).add(5, 'm')
            : moment().add(5, 'm')
          : moment().add(5, 'm');
    }
    return endDate;
  }
  findEarliestTimestamp(obj: Record<string, string>): { key: string; timestamp: string } {
    let earliestKey: string | null = null;
    let earliestTimestamp: Date | null = null;

    for (const [key, value] of Object.entries(obj)) {
      const currentTimestamp = new Date(value);

      // Check if the date is valid
      if (isNaN(currentTimestamp.getTime())) {
        console.error(`Invalid timestamp: '${value}' for key '${key}'`);
        continue;
      }

      if (earliestTimestamp === null || currentTimestamp < earliestTimestamp) {
        earliestTimestamp = currentTimestamp;
        earliestKey = key;
      }
    }

    if (earliestTimestamp && earliestKey) {
      return { key: earliestKey, timestamp: earliestTimestamp.toISOString() };
    }

    return null; // Return null if no valid timestamps found
  }

  onRoadLogisticsOptions = [
    { name: 'All', value: 'all' },
    { name: 'Forward Logistics', value: 'forwardLogistics' },
    { name: 'Reverse Logistics', value: 'reverseLogistics' },
  ];
  selectedOnRoadLogistic = 'all';

  getDataByPageNo({ page }) {
    const pagination = this.pagination()
    pagination.currentPage = page + 1;
    this.pagination.set(pagination)
    this.currentPageComplianceEvents.set(this.temperatureEvents?.[this.agentDetails?._id]?.data?.[this.pagination().currentPage] || this.currentPageComplianceEvents());
    const { startDate, endDate } = this.getStartAndEndDate(false);


    if (this.temperatureEvents?.[this.agentDetails?._id]?.data?.[this.pagination().currentPage]?.length) return;
    this.showLoader = true;
    this.recieveGetEventAction({
      vehicleId: this.agentDetails?._id,
      limit: this.pagination().recordsPerPage,
      skip: this.pagination().recordsPerPage * (page),
      subType: this.selectedSubType()?.sort()?.join(',') || '',
      startDate,
      endDate,
      clearData: false
    })
  }

  showTemperatureComplianceEventsDialog() {
    const pagination = this.pagination()
    pagination.currentPage = 1;
    this.pagination.set(pagination)

    this.handleOnRoadLogisticsData(false);
    const { startDate, endDate } = this.getStartAndEndDate(false);

    this.showLoader = true;
    this.currentPageComplianceEvents.set([])
    this.recieveGetEventAction({
      vehicleId: this.agentDetails?._id,
      limit: this.pagination().recordsPerPage,
      skip: 0,
      subType: this.selectedSubType()?.sort()?.join(',') || '',
      startDate,
      endDate,
      clearData: true
    })
  }

  vehcileIdForEventsSubType = signal<string>('')

  recieveGetEventAction(data) {
    const { vehicleId, skip, limit, subType, startDate, endDate, clearData } = data;

    if (!startDate || !endDate) return;

    clearData && this.store.dispatch(clearEventsDataForAllVehicles())

    vehicleId && this.store.dispatch(getEventsForVehicle({
      vehicleId, skip, limit, subType, typeValue: 'Temperature', complianceType: 'nonCompliance',
      startDate: (new Date(startDate)).getTime(),
      endDate: (new Date(endDate)).getTime()
    }))
    vehicleId !== this.vehcileIdForEventsSubType() && vehicleId && this.store.dispatch(getEventsSubTypesForVehicle({
      vehicleId,
      startDate: (new Date(startDate)).getTime(),
      endDate: (new Date(endDate)).getTime()
    }))
    this.vehcileIdForEventsSubType.set(vehicleId)

  }
}

interface Logistics {
  startDateLabel: string;
  endDateLabel: string;
  startDateValue: number;
  endDateValue: number;
  isChecked: boolean;
}

interface IOnRoadLogisticsData {
  reverseLogistics: Logistics;
  forwardLogistics: Logistics;
  startDate: number;
  endDate: number;
  startDateForGettingEvents: number
  endDateForGettingEvents: number
  startDateLabel: string
  endDateLabel: string

}