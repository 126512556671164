<div class="wrapper">
  <p-splitter gutterSize="10" [style]="{ height: '85vh' }" (onResizeEnd)="dragEnd()" layout="vertical">
    <ng-template pTemplate>
      <div class="panel-container">
        <app-consignment-event-geofence-map (emittRightButtonsAction)="recieveRightButtonsAction($event)"
          [isRightSidebarClosed]="clearMapStatus.minimizedMap" [reloadMapNumber]='reloadMapNumber'
          [agentDetails]="agentDetails" [header]="header" [loaderForSidebarGraph]="loaderForSidebarGraph"
          [dataViewType]='dataViewType' [displayPills]='displayPills' [eventsToPlot]="eventsToPlot"
          [geoFences]="geoFences" [liveDataFromSocket]="liveDataFromSocket" [mapMarkers]="mapMarkers"
          [modalBodyData]="modalBodyData" [pageName]="pageName" [carGpsHistoricalData]="carGpsHistoricalData"
          [routesDetails]="routesDetails" [trackIcons]="trackIcons" [rowData]="rowData"
          [selectedOrderDeliveryGroup]="selectedOrderDeliveryGroup"
          [rightActionButtons]="rightActionButtonsForMinimizedView" [hideLeftActionButtons]="deliveryTempModal" [hideRightActionButtons]="deliveryTempModal"/>
      </div>
    </ng-template>
    <ng-template pTemplate>
      <div>
        <div class="journey-chart-container" (dblclick)="rightSideBarModalClicked()">
          <p-button class="outsideButton" (click)="rightSideBarModalClicked()" icon="pi pi-external-link"
            [disabled]="boxStats.showLoader" pTooltip="Expand" tooltipPosition="bottom"></p-button>
          <app-car-status-graph [chartData]="varyingEChartGraphData" [forceChartHeightInPx]="'350px'"
            [isDataZoomRequired]='true' [isSmallEChart]="false" [isTotalNotRequired]='true'
            [loader]="boxStats.showLoader" [showTitleWithCounts]="true" [show]="boxStats.show"
            (sendDataToPlotMarker)="sendDataToPlotMarker($event)" [updateValue]="graphUpdateValue" />

        </div>

      </div>
    </ng-template>
  </p-splitter>

  <p-toast position="top-center"></p-toast>
</div>

@if (showLoader) {
<div>
  <app-loader></app-loader>
</div>
}
<p-dialog [position]="'center'" [transitionOptions]="'250ms'" [dismissableMask]="true" [draggable]="false"
  [(visible)]="deliveryTempModal" (onShow)="onDialogShow()" (onHide)="onDialogHide()" [modal]="true"
  [style]="{'width': '95vw', 'height':'95vh'}">
  <ng-template pTemplate="header">
    <div class="grid mx-0 flex align-items-center">
      <h5 class="m-0">{{header?.title?.value ? header.title.value : 'Info'}}</h5>
      @if (header?.subTitle?.value) {
      <span style="margin:7px;" class="subTitle" [pTooltip]="header?.subTitle?.tooltip" tooltipPosition="bottom">{{
        header?.subTitle?.value }}</span>
      }
      @if (header?.orderTooltip?.tooltip) {
      <i class="pi pi-info-circle info-icon" [pTooltip]="header?.orderTooltip?.tooltip" tooltipPosition="bottom"
        style="font-size: 15px; margin-left: 5px"></i>
      }
    </div>
  </ng-template>
  <div class="panel-container">
    <app-consignment-event-geofence-map (emittRightButtonsAction)="recieveRightButtonsAction($event)"
      [isRightSidebarClosed]="clearMapStatus.maximizedMap" [reloadMapNumber]='reloadMapNumber'
      [agentDetails]="agentDetails" [header]="header" [loaderForSidebarGraph]="loaderForSidebarGraph"
      [dataViewType]='dataViewType' [displayPills]='displayPills' [eventsToPlot]="eventsToPlot" [geoFences]="geoFences"
      [liveDataFromSocket]="liveDataFromSocket" [mapMarkers]="mapMarkers" [modalBodyData]="modalBodyData"
      [pageName]="pageName" [carGpsHistoricalData]="carGpsHistoricalData" [routesDetails]="routesDetails"
      [trackIcons]="trackIcons" [rowData]="rowData" [selectedOrderDeliveryGroup]="selectedOrderDeliveryGroup"
      [rightActionButtons]="rightActionButtonsForMaximizedView" />
  </div>
</p-dialog>