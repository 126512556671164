import { createAction, props} from "@ngrx/store";
import {  BatchPayload, WarehouseSupplyDetailsPayload, WarehouseSupplyDetailsResponse } from "./interfaces/warehouse.modal";
  
export const loadSupplyList = createAction(
    "[Warehouse Store API] load supply list",
    props<{ payload: WarehouseSupplyDetailsPayload }>()
);


export const loadSupplyListForOrder = createAction(
    "[Warehouse Store API] load supply list for Order",
    props<{ payload: WarehouseSupplyDetailsPayload }>()
);

export const loadSupplyListWithPaginationSuccess = createAction(
    "[Warehouse Module] load supply list and pagination success",
    props<{ response: WarehouseSupplyDetailsResponse }>()
)

export const loadSupplyListForOrderWithPaginationSuccess = createAction(
    "[Warehouse Module] load supply list For Order and pagination success",
    props<{ response: WarehouseSupplyDetailsResponse }>()
)

export const changeShowLoaderValue = createAction(
    '[Effect Warehouse showLoader] on success showLoaderUpdate',
    props<{show : boolean}>()
)

export const warehouseFailureMethod = createAction(
    '[Effect Supply Store API] warehouse on failure',
    props<{err : Error}>()
);

export const createMultipleBatch = createAction(
    "[Warehouse Store API] create batch",
    props<{ locationId: string, payload: BatchPayload[] }>()
);

export const createMultipleBatchSuccess = createAction(
    "[Warehouse Store API] create batch success",
    props<{ res: any }>()
);

export const createMultipleBatchForMultLocations = createAction(
    "[Warehouse Store API] create batch for multiple locations",
    props<{ batchesformultiplelocations: { locationId: string, batches: BatchPayload[] }[]}>()
);

export const createMultipleBatchForMultLocationsSuccess = createAction(
    "[Warehouse Store API] create batch for multiple locations success",
    props<{ res: any }>()
);


export const updateSoldBatchesQuantity = createAction(
    "[Warehouse Store API] update sold batches quantity",
    props<{ batches: { batchId: string, soldQuantity: number }[] }>()
);

export const updateSoldBatchesQuantitySuccess = createAction(
    "[Warehouse Store API] update sold batches quantity success",
    props<{ res: any }>()
);

export const updateSoldBatchesQuantityFailure = createAction(
    "[Warehouse Store API] update sold batches quantity failure",
    props<{ error: any }>()
);

export const loadSelectedSupplyListByIds = createAction(
    "[Warehouse Store API] load Selected supply list",
    props<{ payload: WarehouseSupplyDetailsPayload }>()
);

export const loadSelectedSupplyListByIdsSuccess = createAction(
    "[Warehouse Store API] load Selected supply list Success",
    props<{ response: WarehouseSupplyDetailsResponse }>()
);

export const updateAisleBayToBatches = createAction(
    "[Warehouse Store API] update aisle and bay to batches",
    props<{ payload: any }>()
);

export const updateBathcesWithProductData = createAction(
  "[Warehouse Store API] update Bathces With Product Data",
  props<{ batchId: string; skip: number; limit: number; bayId?: string; aisleId?: string; assetId?: string; stockLocationId?: string }>()
);

export const updateBathcesWithProductDataSuccess = createAction(
  "[Warehouse Store API] update Bathces With Product Data Success",
  props<{ productsByBathces: any, batchId:string }>()
);